.credit-report-container {
  width: 100vw;
  display: flex;
  max-width: 1024px;
  padding: 1rem 1.4rem;
  align-content: center;
  justify-content: center;
  align-items:center;
  box-sizing: border-box;
  margin: 0 auto;

  .text-green {
    color: #A3CD3A;
  }
  
  .text-gray {
    color: #808080;
  }
  
  p, span {
    font-size: 1.4rem;
    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
    }
  }
  
  .main-button {
    background-color: #a3cd3a;
    color: white;
    padding: 15px 25px;
    margin: 20px 0;
    border-radius: 10px;
    font-size: 1.6rem;
    border: none;
    font-family: efectiGo;
    font-weight: bold;
  
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
}

.credit-report-main-container {
  min-height: calc(100vh - 70px);
  margin: -3rem auto 0 auto;
  @media screen and (max-width: 998px) {
    min-height: 46vh;
  }

  @media screen and (max-width: 768px) {
    min-height: 39vh;
  }
}

.credit-report-info-container {
  flex-direction: column;
}

.credit-report-image {
  padding: 2.5rem;
  width: 50%;
  @media screen and (max-width: 998px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 60%;
  }
}

.credit-report-form-image {
  padding: 2.5rem;
  width: 60%;
  @media screen and (max-width: 998px) {
    width: 40%;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.credit-report-legend {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items:center;
  @media screen and (max-width: 998px) {
    margin-top: 30px;
    width: 100%;
  }

  img {
    width:150px;
    @media screen and (max-width: 768px) {
      width: 100px;
    }
  }


}

.credit-report-divider {
  background: #A3CD3A;
  height: 40px;
  width: 100%;
  box-shadow: 0 3px 7px #808080;
}



.why-report-section {
  display: grid;
  grid-template-columns: 200px 40px auto;
  grid-gap: 20px;
  margin-top: 60px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    align-self: center;
    width: 150px;
    grid-row: 1/ span 5
  }

  .indicator {
    width: 40px;
    height: 40px;
    background:#A3CD3A;
    border-radius: 8px;
    align-self: center;
    color: white;
    font-weight: bold;
    display:flex;
    align-content: center;
    align-items:center;
    justify-content: center;
  }

  h1 {
    justify-self: start;
    text-align: left;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  p {
    grid-column: 2 / span 2;
    text-align: left;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  span {
    text-align: left;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}

h1 {
  font-size: 1.8rem;
}


.only-desktop {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.only-mobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
}


.credit-report-form {
  background: white;
  padding: 50px 40px;
  box-shadow: 1px 1px 10px #d3d3d3;
  border-radius: 20px;
  width: 40%;

  @media screen and (max-width: 768px) {
    width: 100%;
    box-shadow: none;
    min-height: 75vh;
    display: flex;
    align-items: center;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    .input-group {
      width: 100%;
    }

    label {
      color: #026D35;
      font-size: 1.2rem;
    }
    input {
      padding-left: 10px;
    }

    .icon-button-container {
      display: flex;
      align-content: center;
      width: 100%;
      button {
        flex-grow: 1;
      }
    }

    .credit-report-form-button {
      border-radius: 10px;
      outline: none;
      background-color:#A3CD3A;
      border: none;
      color: white;
      font-family: efectiGo;
      font-weight: bold;
      padding: 10px 0;
      margin-left: 20px;
    }
  }

  form > * {
    margin: 10px 0;
  }
}


.input-group {
  display: flex;
  flex-direction: column;
  text-align: left;

  input {
    background: #f2f2f2;
    border-radius: 6px;
    height: 1.8rem;
    outline: none;
    appearance: none;
    border:none;
  }
}