// SLIDERS
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: white;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,.rangeslider .rangeslider__fill {
  display: block;  
  background-color: white;
}
.rangeslider .rangeslider__handle {
    background: white;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../assets/img/handler.png");
    background-size: 18px 8px;
    background-blend-mode: exclusion;
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider-horizontal {
  height: 6px;
  border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #10652c;
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}
.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}
.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}
.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}
.rangeslider__labels {
    position: relative;
    margin: 1rem 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0;
}
.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: "";
  width: 10px;
  height: 2px;
  background: #000;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.register-slider-container-go .rangeslider__labels .rangeslider__label-item {
    font-size: 14px;
    cursor: pointer;
    list-style: none;
    font-weight: 300;
    font-size: 0.7rem;
    color: white;
}

////////// CALCULATOR /////////////////
.register-slider-container-go {
  padding: 1rem 2rem;
  background-color: #A3CD3A;
  border-radius: 10px 10px 0 0;
}

.register-slider-container-go h2 {
  text-align: left;
  color: white;
  font-size: 2rem;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.register-slider-container-go h3 {
  text-align: left;
  color: white;
  font-size: 1.6rem;
  font-weight: 700;
  margin-block-end: 0 !important;
}

.title-winput-register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title-winput-register p {
  font-size: 1.5rem;
  font-weight: 200;
  color: white;
}

.title-winput input {
  width: 65px;
  color: #000000;
}

.register-slider-container-go{
  text-align: center;
  color: white;
}

.register-slider-container-go .boton-periodicidad {
  background-color: #A3CD3A !important;
  color: white !important;
  font-weight: 400;
  padding: 1rem 2rem;
  border-radius: 7px;
  margin: 0.5rem;
  border: 1px solid white !important;
  cursor: pointer;
}

.register-slider-container-go .boton-periodicidad:hover {
  background-color: white !important;
  color: #A3CD3A !important;
  font-weight: 600;
}

*:focus{
    outline: none;
}

.register-slider-container-go .period-active{
    background-color: white !important;
    color: #A3CD3A !important;
    font-weight: 600;
}

.slider{
    position: relative;
}

.slider-input-wrapper{
    position: relative;
    width: 90px;
    float: right;
    line-height: 3rem;
}

.slider-input{
    width: 90px !important;
    float: right;
    font-weight: 700;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;
    padding: .15rem .75rem;
    line-height: inherit;
    height: 35px;
}

.slider-input-unit{
    display: inline-block;
    position: absolute;
    right: 0.7rem;
    color: #a8a8a8;
    margin-top: -2px;
    line-height: inherit;
    pointer-events: none;
}

.slider-steps{
    width: 100%;
    position: absolute;
    top: -0.8rem;
    height: 1rem;
    overflow: hidden;
}

.step{
    width: 1px;
    height: 10px;
    background-color: #ccc;
    display: inline-block;
}

.calculator-info{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
}

.info-row-register{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.cat-prom{
    margin: 1rem 0 2rem;
}

.btn {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    color: #ffffff;
    background-color: #A3CD3A;
    border-color: transparent;
    display: block;
    margin-left: initial;
    margin-right: initial;
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 0rem;
    font-size: 1.2rem;
}

.btn:hover{
    background-color: #e05c1a;
}

@media(max-width: 1100px){
  .calculator{
      right: 6rem;
  }
}

@media(max-width: 580px){
  .calculator-info{
    display: block;
  }
}